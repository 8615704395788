import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          d="M29.656 22.296c-2.525 -2.384 -6.819 -4.371 -9.659 -5.688a76.786 76.786 0 0 1 -1.284 -0.6c-1.071 -0.516 -2.538 -1.944 -1.906 -3.584 0.425 -1.109 1.504 -1.669 3.209 -1.669 0.546 0 1.146 0.063 1.787 0.181 2.225 0.416 3.913 1.246 4.869 1.719a0.491 0.491 0 0 0 0.381 0.021 0.5 0.5 0 0 0 0.279 -0.263l2.191 -4.941a0.482 0.482 0 0 0 -0.179 -0.604c-1.544 -1.025 -5.396 -2.546 -9.679 -2.546 -0.619 0 -1.234 0.031 -1.834 0.094 -3.413 0.35 -6.831 1.259 -8.725 5.513 -1.181 2.646 -1.179 5.629 0.004 7.779 1.262 2.346 3.331 3.396 5.95 4.721l0.338 0.171c1.891 0.944 4.156 1.994 5.646 2.684 2.204 1.063 3.019 2.95 2.513 4.088 -0.816 1.834 -2.619 2.237 -5.738 1.354 -2.754 -0.746 -5.294 -2.856 -6.004 -3.484a0.5 0.5 0 0 0 -0.391 -0.119 0.5 0.5 0 0 0 -0.344 0.216L7.687 32.545a0.487 0.487 0 0 0 0.069 0.619c1.759 1.696 3.194 2.684 5.679 3.906 1.671 0.821 4.119 1.316 6.546 1.316 2.538 0 8.734 -0.588 11.454 -6.019 1.734 -3.475 1.088 -7.138 -1.779 -10.069"
          fill="currentColor"
          transform="translate(18, 24)"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
